var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page-list-training" },
    [
      _c("h1", { staticClass: "title mb-4" }, [
        _vm._v(_vm._s(_vm.$t("lk.header.listOfCourses"))),
      ]),
      _c("list-training", {
        attrs: {
          to: "PageViewTraining",
          onlyAccess: true,
          showMarkers: true,
          showCurator: true,
        },
      }),
      _c(
        "CButton",
        {
          staticClass: "cbutton w-100 mt-3",
          attrs: { color: "success" },
          on: { click: _vm.toSubscribe },
        },
        [_vm._v(" " + _vm._s(_vm.$t("lk.training.viewAllCourses")) + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }