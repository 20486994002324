<template>
  <div class="page-list-training">
    <h1 class="title mb-4">{{ $t('lk.header.listOfCourses') }}</h1>
    <list-training
      :to="'PageViewTraining'"
      :onlyAccess="true"
      :showMarkers="true"
      :showCurator="true"
    ></list-training>
    <CButton
      class="cbutton w-100 mt-3"
      color="success"
      @click="toSubscribe"
    >
      {{ $t('lk.training.viewAllCourses') }}
    </CButton>
  </div>
</template>

<script>
import ListTraining from "@/components/training/ListTraining";

export default {
  name: "PageListEducation",
  components: {
    ListTraining,
  },
  methods: {
    toSubscribe(){
      this.$router.push({
        name: 'lk-subscribe',
        query: {
          study: '1'
        }
      })
    }
  }
};
</script>

<style lang="scss">
  .cbutton{
    height: 50px;
  }
</style>
